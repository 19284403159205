import React from 'react';
import { FaHome } from 'react-icons/fa'; // Importing the home icon from react-icons library

const PrivacyPolicy = () => {
  return (
    <div style={{ fontFamily: 'Arial, sans-serif', lineHeight: 1.6, margin: 0, padding: 0, backgroundColor: '#f4f4f4', color: '#333' }}>
      <header style={{ background: 'rgba(1,22,39,.7725490196)', color: '#fff', padding: '15px 20px', display: 'flex', alignItems: 'center' }}>
        <a href="https://flapmax.com" style={{ textDecoration: 'none', color: '#fff', marginRight: '10px' }}>
          <FaHome size={20} /> {/* Using the home icon */}
        </a>
        <center><h4 style={{ margin: 0 }}>Privacy Policy for Flapmax</h4></center>
      </header>
      <div style={{ maxWidth: '800px', margin: 'auto', padding: '20px', background: '#fff', borderRadius: '5px', boxShadow: '0 5px 10px rgba(0,0,0,0.1)' }}>
        <h3>Introduction</h3>
          <p>Flapmax ("we", "us", or "our") respects the privacy of our users ("you" or "your"). This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website Flapmax, including any other media form, media channel, mobile website, or mobile application related or connected thereto (collectively, the "Site").</p>
          <p>Please read this privacy policy carefully. If you do not agree with the terms of this privacy policy, please do not access the site.</p>
        
          <h3>Collection of Your Information</h3>
          <p>We may collect information about you in a variety of ways. The information we may collect on the Site includes:</p>
          <ul>
            <li><strong>Personal Data</strong>: Personally identifiable information, such as your name, shipping address, email address, and telephone number, and demographic information, such as your age, gender, hometown, and interests, that you voluntarily give to us when you register with the Site or when you choose to participate in various activities related to the Site, such as online chat and message boards.</li>
            <li><strong>Derivative Data</strong>: Information our servers automatically collect when you access the Site, such as your IP address, your browser type, your operating system, your access times, and the pages you have viewed directly before and after accessing the Site.</li>
            <li><strong>Financial Data</strong>: Financial information, such as data related to your payment method (e.g., valid credit card number, card brand, expiration date) that we may collect when you purchase, order, return, exchange, or request information about our services from the Site.</li>
            <li><strong>Data From Contests, Giveaways, and Surveys</strong>: Personal and other information you may provide when entering contests or giveaways and/or responding to surveys.</li>
          </ul>
      
          <h3>Use of Your Information</h3>
          <p>Having accurate information about you permits us to provide you with a smooth, efficient, and customized experience. Specifically, we may use information collected about you via the Site to:</p>
          <ul>
            <li>Create and manage your account.</li>
            <li>Deliver targeted advertising, coupons, newsletters, and other information regarding promotions and the Site to you.</li>
            <li>Email you regarding your account or order.</li>
            <li>Fulfill and manage purchases, orders, payments, and other transactions related to the Site.</li>
            <li>Generate a personal profile about you to make future visits to the Site more personalized.</li>
            <li>Increase the efficiency and operation of the Site.</li>
            <li>Monitor and analyze usage and trends to improve your experience with the Site.</li>
            <li>Notify you of updates to the Site.</li>
            <li>Offer new products, services, and/or recommendations to you.</li>
            <li>Perform other business activities as needed.</li>
            <li>Prevent fraudulent transactions, monitor against theft, and protect against criminal activity.</li>
            <li>Process payments and refunds.</li>
            <li>Request feedback and contact you about your use of the Site.</li>
            <li>Resolve disputes and troubleshoot problems.</li>
            <li>Respond to product and customer service requests.</li>
            <li>Send you a newsletter.</li>
            <li>Solicit support for the Site.</li>
          </ul>
   
          <h3>Disclosure of Your Information</h3>
          <p>We may share information we have collected about you in certain situations. Your information may be disclosed as follows:</p>
          <ul>
            <li><strong>By Law or to Protect Rights</strong>: If we believe the release of information about you is necessary to respond to legal process, to investigate or remedy potential violations of our policies, or to protect the rights, property, and safety of others, we may share your information as permitted or required by any applicable law, rule, or regulation.</li>
            <li><strong>Third-Party Service Providers</strong>: We may share your information with third parties that perform services for us or on our behalf, including payment processing, data analysis, email delivery, hosting services, customer service, and marketing assistance.</li>
            <li><strong>Marketing Communications</strong>: With your consent, or with an opportunity for you to withdraw consent, we may share your information with third parties for marketing purposes, as permitted by law.</li>
            <li><strong>Interactions with Other Users</strong>: If you interact with other users of the Site, those users may see your name, profile photo, and descriptions of your activity, including sending invitations to other users, chatting with other users, liking posts, following blogs.</li>
            <li><strong>Online Postings</strong>: When you post comments, contributions or other content to the Site, your posts may be viewed by all users and may be publicly distributed outside the Site in perpetuity.</li>
            <li><strong>Third-Party Advertisers</strong>: We may use third-party advertising companies to serve ads when you visit the Site. These companies may use information about your visits to the Site and other websites that are contained in web cookies in order to provide advertisements about goods and services of interest to you.</li>
            <li><strong>Affiliates</strong>: We may share your information with our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include our parent company and any subsidiaries, joint venture partners or other companies that we control or that are under common control with us.</li>
            <li><strong>Business Partners</strong>: We may share your information with our business partners to offer you certain products, services or promotions.</li>
          </ul>
   
          <h3>Tracking Technologies</h3>
          <p>Cookies and Web Beacons: We may use cookies, web beacons, tracking pixels, and other tracking technologies on the Site to help customize the Site and improve your experience. When you access the Site, your personal information is not collected through the use of tracking technology. Most browsers are set to accept cookies by default. You can remove or reject cookies, but be aware that such action could affect the availability and functionality of the Site.</p>
     
          <h3>Security of Your Information</h3>
          <p>We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse. Any information disclosed online is vulnerable to interception and misuse by unauthorized parties. Therefore, we cannot guarantee complete security if you provide personal information.</p>
  
          <h3>Policy for Children</h3>
          <p>We do not knowingly solicit information from or market to children under the age of 13. If you become aware of any data we have collected from children under age 13, please contact us using the contact information provided below.</p>
   
          <h3>Controls for Do-Not-Track Features</h3>
          <p>Most web browsers and some mobile operating systems include a Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. No uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this Privacy Policy.</p>
     
          <h3>Options Regarding Your Information</h3>
          <p>You may at any time review or change the information in your account or terminate your account by:</p>
          <ul>
            <li>Logging into your account settings and updating your account</li>
            <li>Contacting us using the contact information provided below</li>
          </ul>
          <p>Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, some information may be retained in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our Terms of Use and/or comply with legal requirements.</p>
     
          <h3>Emails and Communications</h3>
          <p>If you no longer wish to receive correspondence, emails, or other communications from us, you may opt-out by:</p>
          <ul>
            <li>Noting your preferences at the time you register your account with the Site.</li>
            <li>Logging into your account settings and updating your preferences.</li>
            <li>Contacting us using the contact information provided below.</li>
          </ul>
    
          <h3>Contact Us</h3>
          <p>If you have questions or comments about this Privacy Policy, please contact us at:</p>
          <ul>
            <li>Email: <a href="mailto:contact@flapmax.com">contact@flapmax.com</a></li>
            <li>Twitter: <a href="https://twitter.com/FlapmaxHQ">https://twitter.com/FlapmaxHQ</a></li>
            <li>LinkedIn: <a href="https://www.linkedin.com/company/flapmax">https://www.linkedin.com/company/flapmax</a></li>
          </ul>
      
      </div>
    </div>
  );
};

export default PrivacyPolicy;
